import React from "react";
import styled from "styled-components";
import { GrMapLocation } from "react-icons/gr";
import { MdPhone, MdAlternateEmail } from "react-icons/md";
import { CiClock2 } from "react-icons/ci";


const Body = styled.div`
  display: flex;
  padding-left: 35%;
`
const Address = styled.div`
  padding-right: 5rem;
  font-size: 18px;
  font-weight: 600;
`
const Phone = styled.div`
  padding-right: 5rem;
  font-size: 18px;
  font-weight: 600;
`
const Email = styled.div`
  padding-right: 5rem;
  font-size: 18px;
  font-weight: 600;
`

const Clock = styled.div`
  padding-right: 5rem;
  font-size: 18px;
  font-weight: 600;
`

const Icon = styled.i`
  font-size: 40px;
  display: flow;
  padding-left: 15px;
  color: rgb(223, 208, 2);
`

function Contact(){

  return (
    <Body>
      <Address>
        <Icon>
          <GrMapLocation />
        </Icon>
        Endereço
      </Address>
      <Phone>
        <Icon>
          <MdPhone />
        </Icon>
        Telefone
      </Phone>
      <Email>
        <Icon>
          <MdAlternateEmail />
        </Icon>
        E-mail
      </Email>
      <Clock>
        <Icon>
          <CiClock2 />
        </Icon>
        horario
      </Clock>
    </Body>
  )
}

export default Contact