import React from 'react';
import './Home.css'


function Home() {
  
  return (
    <section id="home" className="section-home">
      <div className='content-home'>
        <div className='home-title'>
          <h1 className='engineering'>Engenharia</h1>
          <h1 className='integrate'>Integrada</h1>
        </div>
        <div className='service'>
          <p className='text-service'>Construções civil, Engenharia de Produção, Engenharia Mecânica, Engenharia Elétrica.</p>
        </div>
        <div className='button-home'>
          <div className='button-service'>
            <button>Serviços</button>
          </div>
          <div className='button-contact'>
            <button>Contato</button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home