import Navbar from './components/Navbar/NavbarS.jsx';
import Location from './components/Location/Location.jsx';
import Service from './components/job/Job.jsx';
import Home from './components/Home/Home.jsx';

function App() {
  return (
    <>
      <Navbar />
      <Home />
      <Service />
      <Location />
    </>
  );
}

export default App;
