import React from "react";
import './Service.css'

function About(){
  return (
    <section id="about" className="section-about">
      <div className="about-content">
        <div className="title">
          <h4>ALEX SANDRO DE SOUZA</h4>
        </div>
        <div className="text-about">
          <p className="description-about">Sou Engenheiro Mecânico e de Segurança do trabalho especializado em ar condicionado, NR12, NR13, sistema de incêndio, projeto, laudo de ruído e treinamentos das NR's.</p>
          <p className="certificate"><strong>Habilitado pelo CREA-SP.</strong></p>
        </div>
        <div className="button-certificate">
          <button>Agende um visita técnica gratuita!</button>
        </div>
      </div>
    </section>
  );

}


export default About