import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './NavbarS.css'

function NavbarS() {
  return (
    <section className='section-nav'>
      <Navbar bg="dark" data-bs-theme="dark">
        <Container>
          <Nav className="me-auto">
            <Nav.Link href="#Main">Home</Nav.Link>
            <Nav.Link href="#contact">Contato</Nav.Link>
            <Nav.Link href="#service">Serviços</Nav.Link>
            <Nav.Link href="#place">Local</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </section>
  );
}

export default NavbarS;