import './Location.css'
import React from 'react'
import styled from 'styled-components'
import Contact from '../Contact/Contact'

const Text = styled.div`
  padding: 20px 20% 30px 20%;
  font-size: 18px
`

const Title = styled.h2`
  text-align: center;
  color: rgb(223, 208, 2);
`

const SubTitle = styled.h6`
  text-align: center;
`




function Location(){
  return (
    <section className='section-location'>
      <div className='location-content'>
        <h5 className='location-title'>REGULARIZE SUA EMPRESA E INVISTA NO SEU BEM!</h5>
      </div> 
      <Text>
        A emissão do AVCB/CLCB é feita de acordo com cada estabelecimento. Fazemos consultoria e emissão de toda documentaçao (ARTs e atualização de projeto) e sistema de incêndio.

        Esse serviço deve ser feito por profissional capacitado, habilitado e ter o registro regular no Conselho Regional de Engenharia-CREA/SP para que possa emitir a ART- Anotação de Responsabilidade Técnica.
      </Text>
      <Title>
        Quer saber mais?
      </Title>
      <SubTitle>
        Entre em contato, para mais informações!
      </SubTitle>
      <Contact />
    </section>
  )
}

export default Location